import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import classNames from 'classnames'

import './react-table.css'

import './table.css'

function Table({
  columns,
  data,
  noDataText,
  className,
  defaultPageSize,
  ...props
}) {
  return (
    <div>
      <ReactTable
        {...props}
        columns={columns}
        data={data}
        noDataText={noDataText}
        defaultPageSize={defaultPageSize}
        showPageJump={false}
        showPageSizeOptions={false}
        showPaginationTop
        showPaginationBottom={false}
        minRows={0}
        sortable={false}
        className={classNames('reactTable', className)}
      />
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.any)
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.any)
  ]).isRequired,
  noDataText: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultPageSize: PropTypes.number
}

Table.defaultProps = {
  noDataText: 'No data available.',
  className: '',
  defaultPageSize: 5
}

export default Table
